import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { sharedStyles } from '../shared/styles';
import dayjs from 'dayjs';
import InfoText from './InfoText';
import Divider from '@mui/material/Divider';
import clsx from 'clsx';
import { calculateAge } from '../shared/utils';
import { useNavigate, useLocation } from 'react-router-dom';
import MedicalHistoryDialog from './MedicalHistory/MedicalHistoryDialog';
import Button from '@mui/material/Button';
import CommonConsultDialog from '../Components/CommonConsultDialog';
import { checkConsultsWithCommonProcedures } from '../Apis/Consult';
import { consultTypeMapping } from './RecommendedMedicalsTable/Mappings/ConsultTypeMapping';
import { useCareActivity, useCurrentPatientConditions, usePatientSummary } from '../queries';
import PatientSummaryDialog from './PatientSummary/PatientSummaryDialog';
import { PatientConditionDetails } from './PatientConditions/PatientConditionDetails';
import { StatusDetails } from './Status/StatusDetails';
import { riskMapping } from './CoMorbidity/CoMorbidityMappings';
import { absenteeismRiskMapping } from './Absenteeism/AbsenteeismMappings';

const ConsultDetails = ({ config, props }) => {
    const classes = useStyles();
    const sharedClasses = sharedStyles();
    const location = useLocation();
    const [commonConsultOpen, setCommonConsultOpen] = useState(false);
    const patient_id = props.consult.patient.primary_identifier.value;
    const patientSummaryQuery = usePatientSummary(patient_id);
    const medicalConditionsQuery = useCurrentPatientConditions(patient_id);
    const fitnessStatus = patientSummaryQuery.data?.fitness_status;
    const absenteeismStatus = patientSummaryQuery.data?.absenteeism_status;

    useEffect(() => {
        const consultStartedNow = location.state?.consultStartedNow;
        const allProceduresPending = props.consult.procedures.every(
            (p) => p.procedure_status === 'pending'
        );
        if (consultStartedNow && allProceduresPending) {
            checkConsultsWithCommonProcedures(props.consult.consult_id).then((r) =>
                setCommonConsultOpen(true)
            );
        }
    }, [location.state]);

    useEffect(() => {
        patientSummaryQuery.refetch();
    }, []);
    return (
        <div style={{display: 'contents', padding: '100px'}}>
            <CommonConsultDialog
                open={commonConsultOpen}
                handleDialogClose={() => setCommonConsultOpen(false)}
                consult_id={props.consult.consult_id}
            />
            <div style={{ display: 'contents' }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'sticky',
                    top: '-45px',
                    zIndex: '1',
                    backgroundColor: 'white'
                }}>
                    <div className={classes.top}>
                        <img
                            className={classes.profileImage}
                            src={`/${props.consult.client_id}_logo.png`}
                            alt="profile"
                        />

                        <div className={classes.consultDetails}>
                            <div className={classes.primaryDetails}>


                                <div className={sharedClasses.heading}>
                                    {props.consult.patient.first_name} {props.consult.patient.last_name}
                                </div>
                                <div className={classes.badges}>
                                    <div style={{
                                        alignSelf: 'flex-start',
                                        marginBottom: 'auto',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '5px'
                                    }}>
                                        {
                                            fitnessStatus?.co_morbidity_risk_status ?
                                                <StatusDetails
                                                    title={"Co-morbidity risk stratification:"}
                                                    status={fitnessStatus.co_morbidity_risk_status.value}
                                                    comments={
                                                        fitnessStatus?.co_morbidity_risk_status_comments ?? []
                                                    }
                                                    other_comments={
                                                        fitnessStatus?.other_co_morbidity_risk_status_comments ?? []
                                                    }
                                                    badgeColor={riskMapping[fitnessStatus.co_morbidity_risk_status.value].color}
                                                />
                                                :
                                                null
                                        }
                                        {
                                            absenteeismStatus ?
                                                <StatusDetails
                                                    title={"Absenteeism risk status:"}
                                                    status={absenteeismRiskMapping[absenteeismStatus.status.name].label}
                                                    comments={
                                                        absenteeismStatus?.comments ?? []
                                                    }
                                                    other_comments={
                                                        absenteeismStatus?.other_comments ?? []
                                                    }
                                                    badgeColor={absenteeismRiskMapping[absenteeismStatus.status.name].color}
                                                />
                                                :
                                                null
                                        }


                                    </div>

                                    <PatientConditionDetails
                                        patientConditions={medicalConditionsQuery.data ?? []}
                                    />
                                </div>
                            </div>

                            <div className={clsx([sharedClasses.helperText, classes.service])}>
                                <div>Service: {props.consult.tariff_description}</div>
                                <Divider
                                    className={classes.verticalDivider}
                                    orientation="vertical"
                                    flexItem
                                />
                                <div>
                                    Arrived on: {dayjs(props.consult.arrived_at).format('hh:mm A')}
                                </div>
                                <Divider
                                    className={classes.verticalDivider}
                                    orientation="vertical"
                                    flexItem
                                />
                                <div className={clsx([classes.identifier, sharedClasses.helperText])}>
                                    {props.consult.patient.alternative_identifiers[0].type}:{' '}
                                    {props.consult.patient.alternative_identifiers[0].value}
                                </div>
                                <Divider
                                    className={classes.verticalDivider}
                                    orientation="vertical"
                                    flexItem
                                />
                                <div>
                                    Service Date:{' '}
                                    {dayjs(props.consult.service_date).format('DD/MM/YYYY')}
                                </div>
                            </div>
                        </div>
                        {/*<div className={classes.patientIcons}>*/}
                        {/*    {fitnessStatus?.co_morbidity_risk_status ? (*/}
                        {/*        <CoMorbidityDetails*/}
                        {/*            co_morbidity_status={fitnessStatus.co_morbidity_risk_status.value}*/}
                        {/*            co_morbidity_comments={*/}
                        {/*                fitnessStatus?.co_morbidity_risk_status_comments ?? []*/}
                        {/*            }*/}
                        {/*            other_co_morbidity_comments={*/}
                        {/*                fitnessStatus?.other_co_morbidity_risk_status_comments ?? []*/}
                        {/*            }*/}
                        {/*        />*/}
                        {/*    ) : null}*/}
                        {/*    {*/}
                        {/*        <PatientConditionDetails*/}
                        {/*            patientConditions={medicalConditionsQuery.data ?? []}*/}
                        {/*        />*/}
                        {/*    }*/}
                        {/*</div>*/}
                    </div>
                    <Divider className={classes.dividerTop} variant="middle" />
                </div>
                <div className={classes.dividerBottom}/>
                <div className={classes.sidePaddedContainer}>
                    <BasicInformation patient={props.consult.patient} />
                </div>
                <div className={classes.sidePaddedContainer}>
                    <ConsultInformation consult={props.consult} />
                </div>
                <NotesPopup
                    patient={props.consult.patient}
                    consult={props.consult}
                    patient_summary_query={patientSummaryQuery}
                />
            </div>
        </div>
    );
};

const getVisitMonthFromDefinitionIdentifier = (planDefinition) => {
    return `Month: ${planDefinition.substring(planDefinition.lastIndexOf('A') + 1)}`;
};

const ConsultInformation = ({ consult }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { isLoading, isError, data, error } = useCareActivity(
        consult.patient.primary_identifier.value,
        consult.care_activity_id
    );
    const careActivity = data;
    return (
        <>
            <Divider className={classes.divider} variant="fullWidth" />
            <div className={classes.heading}>Consult Details</div>
            {consult.care_activity_id &&
                ['chronic_care_consult', 'workplace_disease_consult'].includes(
                    consult.consult_type
                ) && (
                    <div
                        onClick={() => navigate(`/care-timeline/${consult.consult_id}`)}
                        className={classes.notesInfo}
                    >
                        CARE PLAN
                    </div>
                )}
            <div className={classes.infoContainer}>
                <InfoText
                    className={classes.infoText}
                    placeholder="Consult Type"
                    capitalize={true}
                    text={consultTypeMapping[consult.consult_type]}
                />

                {careActivity?.goal ? (
                    <InfoText
                        className={classes.infoText}
                        capitalize={true}
                        placeholder="Disease Treatment"
                        text={careActivity.goal.disease}
                    />
                ) : null}

                {careActivity?.goal ? (
                    <InfoText
                        className={classes.infoText}
                        placeholder="Treatment Pathway"
                        text={careActivity.goal.pathway.name}
                    />
                ) : null}

                {careActivity?.instantiates ? (
                    <InfoText
                        className={classes.infoText}
                        capitalize={true}
                        placeholder="Visit Month"
                        text={getVisitMonthFromDefinitionIdentifier(
                            careActivity.instantiates.plan_action_identifier
                        )}
                    />
                ) : null}
            </div>
        </>
    );
};

const BasicInformation = ({ patient }) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.heading}>Basic Information</div>
            <div className={classes.infoContainer}>
                <InfoText
                    className={classes.infoText}
                    placeholder="Gender"
                    capitalize={true}
                    text={patient.gender}
                />
                <InfoText
                    className={classes.infoText}
                    placeholder="Age"
                    text={calculateAge(patient.date_of_birth)}
                />
                <InfoText
                    className={classes.infoText}
                    placeholder="Contact No."
                    text={patient.contact_number}
                />
                <InfoText
                    className={classes.infoText}
                    placeholder="Email Address"
                    text={patient.email}
                />
            </div>
        </>
    );
};

const NotesPopup = ({ patient, consult, patient_summary_query }) => {
    const patient_summary = patient_summary_query.data ?? {};
    const [medicalHistoryOpen, setMedicalHistoryOpen] = React.useState(false);
    const [patientSummaryOpen, setPatientSummaryOpen] = React.useState(false);
    const classes = useStyles();
    const navigate = useNavigate();

    const toolTipText = (
        <div className={classes.notesTooltipText}>
            Important information to note about the clinical assessment
        </div>
    );

    const openMedicalHistory = () => {
        setMedicalHistoryOpen(true);
    };

    const closeMedicalHistory = () => {
        setMedicalHistoryOpen(false);
        patient_summary_query.refetch();
    };

    const openPatientSummary = () => {
        setPatientSummaryOpen(true);
    };

    const closePatientSummary = () => {
        setPatientSummaryOpen(false);
    };
    return (
        <div>
            {/*NOTE: DO NOT REMOVE this bit of code. Comment out if not required*/}
            {/*<Divider className={classes.divider} variant="fullWidth" />*/}
            {/*<Grid container direction="row" justifyContent="flex-start" alignItems="center">*/}
            {/*    <div className={classes.heading}>Patient Education</div>*/}
            {/*</Grid>*/}
            {/*<div className={classes.hyperlinkPhrase}>*/}
            {/*    Click&nbsp;*/}
            {/*    {*/}
            {/*        <a*/}
            {/*            href={'https://patienteddirect.elsevier.com/#/ibservice?clientid=94414438'}*/}
            {/*            target={'_blank'}*/}
            {/*            rel={'noreferrer'}*/}
            {/*            className={classes.hyprelinkWord}*/}
            {/*        >*/}
            {/*            {' here '}*/}
            {/*        </a>*/}
            {/*    }*/}
            {/*    &nbsp;to access patient education titles*/}
            {/*</div>*/}
            <Divider className={classes.divider} variant="fullWidth" />
            <div style={{ display: 'flex', gap: '15px' }}>
                {consult.consult_status !== 'completed' ? (
                    <div className={classes.heading}>
                        <Button
                            onClick={openMedicalHistory}
                            style={{
                                cursor: 'pointer',
                                textTransform: 'none',
                                fontFamily:
                                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n" +
                                    "    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n" +
                                    '    sans-serif',
                                borderRadius: '20px',
                                border: '2px solid',
                            }}
                            variant="outlined"
                            className={classes.heading}
                        >
                            Medical and Contextual History
                        </Button>
                    </div>
                ) : null}
                <div className={classes.heading}>
                    <Button
                        onClick={openPatientSummary}
                        style={{
                            cursor: 'pointer',
                            textTransform: 'none',
                            fontFamily:
                                "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n" +
                                "    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n" +
                                '    sans-serif',
                            borderRadius: '20px',
                            border: '2px solid',
                        }}
                        variant="outlined"
                        className={classes.heading}
                    >
                        Patient Summary
                    </Button>
                </div>
            </div>

            <div className={classes.infoContainer}></div>
            <div>
                <MedicalHistoryDialog
                    patient={patient}
                    consult_id={consult.consult_id}
                    open={medicalHistoryOpen}
                    handleMedicalHistoryDialog={closeMedicalHistory}
                />
            </div>
            <div>
                <PatientSummaryDialog
                    patient={patient}
                    patient_summary={patient_summary}
                    open={patientSummaryOpen}
                    handlePatientSummaryDialog={closePatientSummary}
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    primaryDetails: {
        display: 'flex',
        marginBottom: '10px',
        gap: '10px',
    },
    badges: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    top: {
        display: 'flex',
        textTransform: 'capitalize',
        backgroundColor: 'white',
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    consultDetails: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: theme.spacing(2),
    },
    patientIcons: {
        display: 'flex',
        gap: '25px',
    },
    profileImage: {
        height: '82px',
        // width: '82px'
    },
    identifier: {
        textAlign: 'end',
    },
    heading: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    infoText: {
        padding: theme.spacing(1),
        '&:not(:first-child)': {
            borderLeft: '1px solid #EEEEEE',
        },
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    dividerTop: {
        marginTop: theme.spacing(2),
    },
    dividerBottom: {
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    service: {
        display: 'flex',
        gridColumn: 'span 2',
    },
    verticalDivider: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    notesHeading: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        paddingRight: '6px',
    },
    notesInfo: {
        marginTop: '6px',
        color: 'rgba(0, 0, 0, 0.36)',
        fontSize: '14px',
        cursor: 'pointer',
        '&:hover': {
            color: 'black',
        },
    },
    notesTooltipText: {
        color: 'rgba(0, 0, 0, 0.46)',
        fontSize: '15px',
        fontStyle: 'italic',
    },
    hyperlinkPhrase: {
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        color: '#666666',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    },
    hyprelinkWord: {
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        color: '#666666',
        fontWeight: '500',
        '&visited': {
            color: '#666666',
        },
    },
    sidePaddedContainer:{
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    }
}));

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        fontSize: 16,
        width: 17,
        '&::before': {
            border: '1px solid #000',
            backgroundColor: '#fff',
            boxSizing: 'border-box',
        },
    },
    tooltip: {
        background: '#fff',
        color: '#000',
        border: '1px solid #000',
        borderRadius: 3,
    },
}));

export default ConsultDetails;
